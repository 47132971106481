document.addEventListener('turbolinks:load', () => {
  mybutton = document.getElementById("scroll_btn");

  mybutton.addEventListener("click", topFunction);

  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      mybutton.classList.add("show");
    } else {
      mybutton.classList.remove("show");
    }
  }

  function topFunction() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
});