import React, { Component } from "react";
import Slider from "react-slick";
import ImagePreview from "./ImagePreview";

export default class Carousel extends Component {
  state = { previewUrl: '' }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: this.props.images.length > 1 ? 2 : 1,
      className: 'slides',
      responsive: [
        {
          breakpoint: 858,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

    return (
      <>
        <Slider {...settings}>
          {this.props.images.map((image, index) =>
            <div key={index}>
              <a className='carousel-link' href={'/artworks/' + this.props.artworks[index].id} >
                <img className='carousel-img' src={image.url} onClick={() => this.setState({previewUrl: image.url})} /> 
              </a>

              <div className='carousel-text'>
                <div className='img-name'>
                  {this.props.artworks[index].name}
                </div>

                <div className='img-description'>
                  {this.props.artworks[index].description}
                </div>
              </div>
            </div>
          )}
        </Slider>
      </>
    );
  }
}
