import React, {useState} from 'react'
import ImagePreview from "./ImagePreview";

const Image = ({url}) => {
  const[isPreview, setPreview] = useState(false)

  return (
    <>
      <img src={url} onClick={() => setPreview(true)} /> 

      {
        isPreview && <ImagePreview url={url} hidePreview={() => setPreview(false)} /> 
      }
    </>
  )
}

export default Image
