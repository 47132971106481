import React from 'react'

const ImagePreview = ({url, hidePreview}) => {
  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'scroll';
    }
  }, []);

  return (
    <div className='modal-preview'>
      <button className='modal-preview__close' onClick={hidePreview}>✕</button> 

      <img className='modal-preview__img' src={url} onClick={hidePreview} />
    </div>
  )
}

export default ImagePreview 
